import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function getGroupPermissions(groupId) {
  return request(`${ROOT_SERVER_URL}/api/permissions/${groupId}`);
}

export function createGroupPermission(data) {
  return request(`${ROOT_SERVER_URL}/api/permissions`, {
    data,
    method: 'POST',
  });
}

export function deleteGroupPermission(permissionId, type) {
  return request(`${ROOT_SERVER_URL}/api/permissions/${permissionId}`, {
    data: { type },
    method: 'DELETE',
  });
}

export function getGroupFeedsPermissions(groupId) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/${groupId}`);
}

export function getDataFeedPermissionApprovals(dataFeedId) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/approvals/data-feed/${dataFeedId}`);
}

export function getPermissionApprovals(permissionId) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/approvals/${permissionId}`);
}

export function putPermissionApproval(data) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/approval`, {
    data,
    method: 'POST',
  });
}

export function updatePermissionApproval(approvalId, data) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/approval/${approvalId}`, {
    data,
    method: 'PUT',
  });
}

export function getGroupWorkbenchInstancePermissions(groupId) {
  return request(`${ROOT_SERVER_URL}/api/permissions-workbench-instance/${groupId}`);
}

export function putGroupWorkbenchInstancePermissions(data) {
  return request(`${ROOT_SERVER_URL}/api/permissions-workbench-instance/${data.groupId}`, {
    data,
    method: 'PUT',
  });
}

export function getServicePermissionSummary(serviceId, { isCsv }) {
  const optionalCsvParam = isCsv ? '&type=csv' : '';
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/service-permission-summary?serviceId=${serviceId}${optionalCsvParam}`);
}

export function putGroupFeedsPermissions(data) {
  return request(`${ROOT_SERVER_URL}/api/permissions-data-feeds/${data.groupId}`, {
    data,
    method: 'PUT',
  });
}

export function getDataInventoryServicePermissions(entityId, serviceId) {
  return request(`${ROOT_SERVER_URL}/api/permissions/${entityId}/data-feeds?serviceId=${serviceId}`);
}

export function getWorkbenchPermissions(entityId) {
  return request(`${ROOT_SERVER_URL}/api/permissions/${entityId}/workbench`);
}
