<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import {
    Button,
    Column,
    FormGroup,
    Grid,
    InlineNotification,
    MultiSelect,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    TextArea,
  } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';
  import truncate from 'lodash/truncate';
  import * as yup from 'yup';

  import { Accordion, AccordionItem, CopyButton, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../../components/LoadingButton.svelte';
  import DataFeedSubscribersModal from '../DataFeedSubscribersModal.svelte';
  import StatusIcon from '../../../components/StatusIcon.svelte';

  import { getDataFeedPermissionApprovals, updateDataFeed } from '../../../services';
  import { convertToLocalDisplayTime, getServerErrorMessage } from '../../../utils';
  import { DataInventoryServiceIds, DataInventoryServiceNames } from '../../../../shared/constants';
  import { permissionTypeDescription } from '../../../../server/db/constants';

  export let dataFeedId, dataFeed;

  let formSubmitMessage,
    approvals = [];
  let modals = {
    dataFeedSubscribers: { open: false },
  };

  const dispatch = createEventDispatcher();

  const schema = yup.object().shape({
    notes: yup.string().nullable(),
    approvalServiceIds: yup.array().nullable(),
    sftpCustomFolderPath: yup.string().nullable(),
  });

  async function fetchApprovals() {
    try {
      approvals = await getDataFeedPermissionApprovals(dataFeedId);
    } catch (error) {
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to load data feed approvals! Please refresh page for retry.',
      };
    }
  }

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;
    try {
      await updateDataFeed(dataFeedId, values);
      dispatch('dataFeedUpdated');
      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Data Feed updated successfully!',
      };
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Verify your submission and try again.';
      console.error('[DataFeedBasicInformation] Failed to update data feed data!', errorMessage);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to update data feed! Verify your submission and try again.',
      };
    } finally {
      setSubmitting(false);
      document.querySelector('#main-content').scrollTo(0, 0);
    }
  }

  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, data: forwardData },
    };
  }

  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { open: false },
    };
  }

  onMount(fetchApprovals);

  $: services = Object.keys(DataInventoryServiceIds).map((id) => ({
    id: DataInventoryServiceIds[id],
    text: DataInventoryServiceNames[id],
  }));
</script>

{#if formSubmitMessage}
  <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
{/if}
<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading">Read-Only Fields</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Internal ID</StructuredListCell>
            <StructuredListCell>
              <div class="id-controls">
                {truncate(dataFeed.id, { length: 20 })}
                <Button
                  class="copy-button"
                  hasIconOnly
                  icon={Copy16}
                  iconDescription="Copy to clipboard"
                  kind="ghost"
                  size="small"
                  on:click={() => copy(dataFeed.id)}
                />
              </div>
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>AWS Lake Available</StructuredListCell>
            <StructuredListCell>
              <span
                on:click={() => {
                  if (dataFeed.awsLakeAvailable) {
                    openModal('dataFeedSubscribers', {
                      dataFeedId,
                      dataFeedName: dataFeed.name,
                      permissionType: 'data-feeds-lake',
                    });
                  }
                }}
              >
                <StatusIcon valid={dataFeed.awsLakeAvailable} />
              </span>
              {#if dataFeed.minAwsLakeAvailable && dataFeed.maxAwsLakeAvailable}
                <em class="info">
                  [{dataFeed.minAwsLakeAvailable} — {dataFeed.maxAwsLakeAvailable}]
                </em>
              {/if}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>AWS Arbitrated PCAPs Available</StructuredListCell>
            <StructuredListCell>
              <span
                on:click={() => {
                  if (dataFeed.awsArbitratedPcapsAvailable) {
                    openModal('dataFeedSubscribers', {
                      dataFeedId,
                      dataFeedName: dataFeed.name,
                      permissionType: 'data-feeds-aws-arbitrated-pcaps',
                    });
                  }
                }}
              >
                <StatusIcon valid={dataFeed.awsArbitratedPcapsAvailable} />
              </span>
              {#if dataFeed.minAwsArbitratedPcapsAvailable && dataFeed.maxAwsArbitratedPcapsAvailable}
                <em class="info">
                  [{dataFeed.minAwsArbitratedPcapsAvailable} — {dataFeed.maxAwsArbitratedPcapsAvailable}]
                </em>
              {/if}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>SFTP05/CrushFTP Available</StructuredListCell>
            <StructuredListCell>
              <span
                on:click={() => {
                  if (dataFeed.sftp05Available) {
                    openModal('dataFeedSubscribers', {
                      dataFeedId,
                      dataFeedName: dataFeed.name,
                      permissionType: 'data-feeds-crushftp',
                    });
                  }
                }}
              >
                <StatusIcon valid={dataFeed.sftp05Available} />
              </span>
              {#if dataFeed.minSftp05Available && dataFeed.maxSftp05Available}
                <em class="info">
                  [{dataFeed.minSftp05Available} — {dataFeed.maxSftp05Available}]
                </em>
              {/if}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Earliest Start Date</StructuredListCell>
            <StructuredListCell>
              {dataFeed.minAvailable && dataFeed.minAvailable !== '0000-00-00' ? dataFeed.minAvailable : '-'}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Latest End Date</StructuredListCell>
            <StructuredListCell>
              {dataFeed.maxAvailable ?? '-'}
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Metadata</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Created</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(dataFeed.createdAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Created By</StructuredListCell>
            <StructuredListCell>{dataFeed.createdBy}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(dataFeed.updatedAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated By</StructuredListCell>
            <StructuredListCell>{dataFeed.updatedBy}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Approvals</h2>
      <div class="approvals">
        <Accordion>
          {#each approvals as approval}
            <AccordionItem>
              <svelte:fragment slot="title">
                <strong
                  >{approval.permissionDetails?.group?.name ?? 'Unknown'}{approval.permissionDetails?.type
                    ? ` | ${permissionTypeDescription[approval.permissionDetails?.type]}`
                    : ''}</strong
                >
                <div>
                  {approval.permissionDataFeedDetails?.startDate
                    ? `${approval.permissionDataFeedDetails?.startDate} - ${approval.permissionDataFeedDetails?.endDate}`
                    : 'Expired or deleted'}{approval.permissionDataFeedDetails?.isOngoing ? ' | On going' : ''}{approval
                    .permissionDataFeedDetails?.expiresAt
                    ? ` | Demo, expires at: ${approval.permissionDataFeedDetails?.expiresAt}`
                    : ''}
                </div>
              </svelte:fragment>
              <div>
                <div class="approval-link">
                  <TextInput readonly labelText="Link to approval" placeholder="No link for this approval" value={approval.url} />
                  {#if approval.url}
                    <CopyButton text={approval.url} feedback="Copied to clipboard" copy={(text) => copy(text)} />
                  {/if}
                </div>
                <TextArea readonly labelText="Note" placeholder="No note for this approval" value={approval.note} />
              </div>
            </AccordionItem>
          {/each}
        </Accordion>
      </div>
    </Column>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading column-heading-form">Editable Fields</h2>
      <Form
        {schema}
        initialValues={dataFeed}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:submitForm
        let:isSubmitting
        let:setValue
        let:errors
        let:touched
        let:values
      >
        <FormGroup>
          <TextInput
            helperText="Useful when feeds have been renamed, sourced from NY4, or prepared for a specific client."
            invalid={touched.sftpCustomFolderPath && !!errors.sftpCustomFolderPath}
            invalidText={errors.sftpCustomFolderPath}
            labelText="Custom SFTP05 Folder Path"
            name="sftpCustomFolderPath"
            placeholder="Custom SFTP05 Folder Path"
            type="text"
            value={values.sftpCustomFolderPath}
            on:change={({ detail: text }) => setValue('sftpCustomFolderPath', text)}
          />
          <MultiSelect
            filterable
            helperText="A reminder to get approval will appear for this feed while provisioning for a client, only for the selected service(s)."
            items={services}
            name="approvalServiceIds"
            placeholder="Request approval"
            spellcheck="false"
            titleText="Request approval"
            selectedIds={values.approvalServiceIds}
            on:select={({ detail: { selectedIds } }) => setValue('approvalServiceIds', selectedIds)}
          />
          <TextArea
            invalid={touched.notes && !!errors.notes}
            invalidText={errors.notes}
            labelText="Notes"
            name="notes"
            placeholder="Additional notes"
            type="text"
            value={values.notes}
            on:change={({ target }) => setValue('notes', target.value)}
          />
        </FormGroup>
        <div class="form-controls">
          <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
        </div>
      </Form>
    </Column>
  </Row>
</Grid>

{#if modals.dataFeedSubscribers.open}
  <DataFeedSubscribersModal open {...modals.dataFeedSubscribers.data} on:close={() => closeModal('dataFeedSubscribers')} />
{/if}

<style>
  .approvals {
    max-height: 225px;
    overflow-y: auto;
  }
  .info {
    font-size: 12px;
    color: var(--cds-text-helper);
  }
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .approval-link {
    display: flex;
    align-items: flex-end;
  }

  .column-heading + :global(.bx--structured-list) {
    margin-bottom: 2.5rem;
  }
  .column-heading + :global(.bx--structured-list:last-child) {
    margin-bottom: 0.5rem;
  }
  .column-heading + :global(.bx--structured-list .bx--structured-list-td) {
    width: 50%;
  }

  .column-heading-form + :global(.sveltejs-forms > .bx--fieldset > .bx--form-item:first-child) {
    margin: 0 0 0.75rem;
  }

  @media screen and (min-width: 66rem) {
    .column-heading-form {
      margin-bottom: 1.875rem;
    }
    .column-heading-form + :global(.sveltejs-forms) {
      margin-top: 0;
    }
  }

  .id-controls :global(.bx--btn) {
    min-height: unset;
    padding-top: 0;
  }
  .id-controls :global(.bx--btn svg > path) {
    fill: var(--cds-link-01) !important;
  }
</style>
