import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function getServiceDataFeeds(serviceId) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds?serviceId=${serviceId}`);
}

export function getDataFeedInventorySummary() {
  return request(`${ROOT_SERVER_URL}/api/data-feeds`);
}

export function refreshAndGetDataFeedInventorySummary() {
  return request(`${ROOT_SERVER_URL}/api/data-feeds?forceRefresh=true`);
}

export function getDataFeed(dataFeedId) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/${dataFeedId}`);
}

export function getDataFeedApprovalServices(dataFeedId) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/${dataFeedId}/feed-approval-services`);
}

export function getDataFeedsApprovalService(serviceId) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/${serviceId}/service-approval`);
}

export function getDataFeedInventoryServiceStatus() {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/services`);
}

export function getDataFeedInventoryRegression(serviceId) {
  return request(`${ROOT_SERVER_URL}/api/data-feed-inventory-regression?serviceId=${serviceId}`);
}

export function addDataFeed(data) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds`, {
    data,
    method: 'POST',
  });
}

export function updateDataFeed(dataFeedId, changes) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/${dataFeedId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function resolveDataFeedInventoryRegression(regression, serviceId) {
  return request(`${ROOT_SERVER_URL}/api/data-feed-inventory-regression/${serviceId}`, {
    data: { regression },
    method: 'PUT',
  });
}

export function getDataFeedSubscribers(dataFeedId, permissionType) {
  return request(`${ROOT_SERVER_URL}/api/data-feeds/${dataFeedId}/${permissionType}/subscribers`);
}
