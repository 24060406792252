<script>
  import { createEventDispatcher } from 'svelte';
  import * as yup from 'yup';

  import { InlineNotification, Modal, TextArea, TextInput } from 'carbon-components-svelte';

  import LoadingSpinner from '../../../../components/LoadingSpinner.svelte';
  import { putPermissionApproval, updatePermissionApproval } from '../../../../services';

  export let approval = {},
    entitlement,
    groupId,
    open = false;

  let urlValidationError = null;
  let loading = false;
  let resultNotification;

  const dispatch = createEventDispatcher();

  const schema = yup.object().shape({
    url: yup.string().url('Invalid URL').required('Valid URL is required'),
  });

  function validateUrl(url) {
    if (!url) {
      urlValidationError = null;
      return;
    }

    schema
      .validate({ url })
      .then(() => {
        urlValidationError = null;
      })
      .catch((error) => {
        urlValidationError = error?.message ?? 'Invalid URL';
      });
  }

  function validApproval() {
    return approval.url || approval.note;
  }

  function handleCustomChangeEvent(field) {
    return function onCustomChange({ detail: value }) {
      if (field === 'url') {
        validateUrl(value);
      }

      approval = { ...approval, [field]: value };
    };
  }

  function handleChangeEvent(field) {
    return function onChange({ target: { value } }) {
      approval = { ...approval, [field]: value };
    };
  }

  async function updateApproval() {
    if (!validApproval()) {
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'You need to add a URL or a note to proceed',
      };

      return;
    }

    loading = true;

    try {
      const { id, url, note } = approval;
      const updatedApproval = await (id
        ? updatePermissionApproval(id, { url, note, groupId })
        : putPermissionApproval({ ...entitlement, url, note, groupId }));
      dispatch('close', updatedApproval);
    } catch (error) {
      console.error('[EditApprovalModal] Failed to update approval!', error);
      resultNotification = {
        hideCloseButton: false,
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to update approval! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }
</script>

<Modal
  modalHeading="Edit Approval"
  preventCloseOnClickOutside={true}
  primaryButtonText="Save"
  secondaryButtonText="Cancel"
  size="sm"
  bind:open
  on:click:button--secondary={() => dispatch('close')}
  on:close
  on:submit={updateApproval}
>
  <LoadingSpinner small {loading}>
    {#if resultNotification}
      <InlineNotification
        kind={resultNotification.kind}
        lowContrast
        title={resultNotification.title}
        subtitle={resultNotification.subtitle}
      />
    {/if}
    <div class="form">
      <TextInput
        value={approval.url}
        on:change={handleCustomChangeEvent('url')}
        invalid={!!urlValidationError}
        invalidText={urlValidationError}
        size="sm"
        labelText="URL to approval"
        placeholder="Link to approval"
      />
      <TextArea value={approval.note} on:change={handleChangeEvent('note')} labelText="Notes" placeholder="Notes" />
    </div>
  </LoadingSpinner>
</Modal>

<style>
  .form :global(.bx--form-item .bx--text-area__label-wrapper) {
    margin-top: 1rem;
  }
</style>
