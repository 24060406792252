<script>
  import truncate from 'lodash/truncate';
  import { Link, Tag } from 'carbon-components-svelte';
  import ArrowRight16 from 'carbon-icons-svelte/lib/ArrowRight16';

  export let difference;

  const keyToText = {
    startDate: 'Start Date',
    endDate: 'End Date',
    isOngoing: 'Ongoing',
    onGoingMaxDate: 'Max Date (Ongoing)',
    expiresAt: 'Expires At',
    approvalNote: 'Approval Note',
    approvalUrl: 'Approval URL',
  };

  function validUrl(possibleUrl) {
    try {
      // eslint-disable-next-line no-new
      new URL(possibleUrl);
      return true;
    } catch (_) {
      return false;
    }
  }

  function getHostName(url) {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      console.error('[AuditTableEventDataCellDataFeeds] Not valid URL', error);
      return 'unknown hostname';
    }
  }
</script>

<ul class="diff-list">
  {#each Object.entries(difference) as [changedKey, { previous, change }]}
    <li>
      <Tag type="high-contrast" size="sm">{keyToText[changedKey] ?? changedKey}</Tag>
      {#if changedKey === 'approvalUrl' && validUrl(previous)}
        <Link href={previous} target="_blank">Go to old approval ({getHostName(previous)})</Link>
      {:else if changedKey === 'approvalNote'}
        {truncate(previous)}
      {:else}
        {previous}
      {/if}
      <ArrowRight16 />
      {#if changedKey === 'approvalUrl' && validUrl(change)}
        <Link href={change} target="_blank">Go to new approval ({getHostName(change)})</Link>
      {:else if changedKey === 'approvalNote'}
        <strong>{truncate(change)}</strong>
      {:else}
        <strong>{change}</strong>
      {/if}
    </li>
  {/each}
</ul>

<style>
  .diff-list {
    margin-left: 1.5rem;
  }

  .diff-list :global(svg) {
    margin-bottom: -0.2rem;
  }
</style>
