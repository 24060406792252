<script>
  import truncate from 'lodash/truncate';
  import { Link } from 'carbon-components-svelte';

  import ChangedFeedDifference from '../../pages/groups/group-permissions/data-feeds/ChangedFeedDifference.svelte';

  export let eventData;

  function getHostName(url) {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      console.error('[AuditTableEventDataCellDataFeeds] Not valid URL', error);
      return 'unknown hostname';
    }
  }
</script>

{#if eventData.created?.length}
  <h6>Added:</h6>
  {#each eventData.created as { name, startDate, endDate, approvalUrl, approvalNote }}
    <div>{name}: {startDate} - {endDate}</div>
    {#if approvalUrl}
      <div class="note">
        <Link href={approvalUrl} target="_blank">Go to approval ({getHostName(approvalUrl)})</Link>
      </div>
    {/if}
    {#if approvalNote}
      <div class="note">
        <em><strong>Approval note: </strong>{truncate(approvalNote)}</em>
      </div>
    {/if}
  {/each}
{/if}
{#if eventData.updated?.length}
  <h6>Updated:</h6>
  {#each eventData.updated as { name, difference }}
    <div>{name}: <ChangedFeedDifference {difference} /></div>
  {/each}
{/if}
{#if eventData.deleted?.length}
  <h6>Deleted:</h6>
  <div>{eventData.deleted.join(', ')}</div>
{/if}

<style>
  .note {
    padding-left: 1rem;
  }
  .note em {
    font-size: 12px;
    color: var(--cds-text-helper);
  }
</style>
