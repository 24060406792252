<script>
  import { v4 as uuidv4 } from 'uuid';
  import * as yup from 'yup';
  import {
    Accordion,
    AccordionItem,
    Button,
    Checkbox,
    Modal,
    InlineNotification,
    Tag,
    TextArea,
    TextInput,
  } from 'carbon-components-svelte';
  import startCase from 'lodash/startCase';
  import isEmpty from 'lodash/isEmpty';
  import WarningFilled from 'carbon-icons-svelte/lib/WarningFilled16';

  import ChangedFeedDifference from './ChangedFeedDifference.svelte';
  import { putGroupFeedsPermissions, getPermissionApprovals } from '../../../../services';
  import { getServerErrorMessage } from '../../../../utils';

  export let dbFeeds,
    feedListRequiringApproval,
    fetchError,
    groupId,
    hasRunningConnection,
    permissionId,
    permissionTableLoading,
    serviceId,
    validationErrors,
    visibleFeeds,
    visibleApprovals;

  let confirmationModal = false,
    approvals = {},
    approvalsValid = true,
    urlValidationErrors = {},
    understood = false;

  const getPermissionDifference = (existingPermission, updatedPermission) => {
    const difference = {};
    Object.keys(existingPermission).forEach((key) => {
      if (existingPermission[key] !== updatedPermission[key]) {
        difference[key] = { previous: existingPermission[key], change: updatedPermission[key] };
      }
    });

    return Object.keys(difference).length ? difference : null;
  };

  const urlValidationSchema = yup.object().shape({
    url: yup.string().url('Invalid URL').required('Valid URL is required'),
  });

  const typeToColor = {
    created: 'green',
    updated: 'cyan',
    deleted: 'red',
  };

  function resetValues() {
    confirmationModal = false;
    approvals = {};
    approvalsValid = true;
    urlValidationErrors = {};
    understood = false;
  }

  function validateUrl({ id, url }) {
    if (!url) {
      delete urlValidationErrors[id];
      urlValidationErrors = { ...urlValidationErrors };
      return;
    }

    urlValidationSchema
      .validate({ url })
      .then(() => {
        delete urlValidationErrors[id];
        urlValidationErrors = { ...urlValidationErrors };
      })
      .catch((error) => {
        urlValidationErrors = { ...urlValidationErrors, [id]: { error: error?.message ?? 'Invalid URL' } };
      });
  }

  function validApprovals() {
    if (isEmpty(approvals)) {
      return false;
    }

    return Object.keys(approvals).length === requireApproval.length;
  }

  function handleCustomChangeEvent({ id, feedId }, field) {
    return function onCustomChange({ detail: value }) {
      if (field === 'url') {
        validateUrl({ id, url: value });
      }

      if (!value && !approvals[id]?.note) {
        delete approvals[id];
        approvals = { ...approvals };
        return;
      }

      approvals = { ...approvals, [id]: { ...(approvals[id] ?? {}), feedId, [field]: value } };
    };
  }

  function handleChangeEvent({ id, feedId }, field) {
    return function onChange({ target: { value } }) {
      if (!value && !approvals[id]?.url) {
        delete approvals[id];
        approvals = { ...approvals };
        return;
      }

      approvals = { ...approvals, [id]: { ...(approvals[id] ?? {}), feedId, [field]: value } };
    };
  }

  $: feedDifference = [...dbFeeds, ...visibleFeeds].reduce(
    (obj, curr) => {
      const alreadyExisting = dbFeeds.find(({ id }) => curr.id === id);
      const currentlyIncluded = visibleFeeds.find(({ id }) => curr.id === id);
      if (!alreadyExisting) {
        const { name, feedId, startDate, endDate, isOngoing, onGoingMaxDate, expiresAt } = curr;
        const addedFeed = {
          id: uuidv4(),
          name,
          feedId,
          startDate,
          endDate,
          permissionId,
          isOngoing,
          onGoingMaxDate: onGoingMaxDate || null,
          expiresAt: expiresAt || null,
        };
        return { ...obj, created: { ...obj.created, [curr.id]: addedFeed } };
      }

      if (alreadyExisting && !currentlyIncluded) {
        // todo: delete approval?
        return { ...obj, deleted: { ...obj.deleted, [curr.id]: curr } };
      }

      const difference = getPermissionDifference(alreadyExisting, currentlyIncluded);
      if (difference) {
        const { name, startDate, endDate, isOngoing, onGoingMaxDate, expiresAt } = curr;
        const updatedFeed = {
          id: alreadyExisting.id,
          name,
          startDate,
          endDate,
          isOngoing,
          onGoingMaxDate: onGoingMaxDate || null,
          expiresAt: expiresAt || null,
          difference,
        };
        return { ...obj, updated: { ...obj.updated, [curr.id]: updatedFeed } };
      }
      return obj;
    },
    { created: {}, updated: {}, deleted: {} }
  );

  const handleSubmitConfirmationModal = async () => {
    approvalsValid = true;

    if (requireApproval.length && !validApprovals()) {
      approvalsValid = false;
      return;
    }

    permissionTableLoading = true;
    confirmationModal = false;

    try {
      const { newFeedPermissions } = await putGroupFeedsPermissions({
        ...feedDifference,
        approvals,
        permissionId,
        groupId,
        serviceId,
      });

      const savedApprovals = await getPermissionApprovals(permissionId);

      visibleFeeds = newFeedPermissions;
      dbFeeds = newFeedPermissions;
      visibleApprovals = savedApprovals;
    } catch (error) {
      console.error('[SaveFeeds] Failed to put group feed permissions!', error);
      fetchError = getServerErrorMessage(error) || 'Failed to save group feed permissions.';
    } finally {
      permissionTableLoading = false;
    }
  };

  $: validationErrorExists = Object.values(validationErrors).some((rowValidationErrors) =>
    Object.values(rowValidationErrors)?.some(Boolean)
  );

  $: submittable = !isEmpty(feedDifference.created) || !isEmpty(feedDifference.updated) || !isEmpty(feedDifference.deleted);
  $: feedSetRequiringApproval = new Set(feedListRequiringApproval);
  $: requireApproval = Object.values(feedDifference.created).filter(({ feedId }) => feedSetRequiringApproval.has(feedId)) ?? [];
</script>

{#if visibleFeeds.length || dbFeeds.length}
  <div class="save-button">
    <Button
      disabled={validationErrorExists || !submittable}
      on:click={() => {
        confirmationModal = true;
      }}
    >
      Save
    </Button>
  </div>
{/if}

<Modal
  modalHeading="Confirm changes"
  preventCloseOnClickOutside={true}
  primaryButtonText={`Apply${hasRunningConnection ? ' (option blocked until current entitlement is finished)' : ''}`}
  secondaryButtonText="Cancel"
  size="lg"
  primaryButtonDisabled={hasRunningConnection || !isEmpty(urlValidationErrors) || (requireApproval.length && !understood)}
  on:click:button--secondary={resetValues}
  on:close={resetValues}
  bind:open={confirmationModal}
  on:submit={handleSubmitConfirmationModal}
>
  <div class="tag-align">
    <p>Are you sure you want to apply the following changes?</p>
    {#if requireApproval.length}
      <br />
      <InlineNotification lowContrast hideCloseButton kind="warning">
        <div slot="title"><strong>Important!</strong></div>
        <div slot="subtitle">
          Feeds marked with this icon <strong>require</strong> exchange approval prior to entitlement. Make sure you have acquired the
          approval <strong>before</strong> you proceed. <br /> <br />
          Example: <Tag type="green"><WarningFilled /> example_feed_name</Tag>
        </div>
      </InlineNotification>
    {/if}
    {#if !approvalsValid}
      <InlineNotification
        kind="error"
        title="Error:"
        subtitle="You need to add a URL or a note for all approvals to proceed"
        lowContrast={true}
        hideCloseButton={true}
      />
    {/if}
  </div>
  {#each ['created', 'updated', 'deleted'] as type}
    {#if Object.keys(feedDifference[type]).length}
      <div class="change-type-wrapper tag-align">
        <h5>{startCase(type)}</h5>
        <Accordion size="sm">
          <ul>
            {#each Object.values(feedDifference[type]) as { id, feedId, name, startDate, endDate, difference }}
              {#if type === 'created' && feedSetRequiringApproval.has(feedId)}
                <AccordionItem open>
                  <div slot="title">
                    <Tag type={typeToColor[type]}><WarningFilled /> {name}</Tag>
                    {#if difference}
                      <ChangedFeedDifference {difference} />
                    {:else}
                      <strong>{startDate} &mdash; {endDate}</strong>
                    {/if}
                  </div>
                  <div>
                    <TextInput
                      value={approvals[id]?.url}
                      invalid={urlValidationErrors[id]}
                      invalidText={urlValidationErrors[id]?.error}
                      on:change={handleCustomChangeEvent({ id, feedId }, 'url')}
                      hideLabel
                      size="sm"
                      labelText="URL to approval"
                      placeholder="Link to approval"
                    />
                    <TextArea
                      value={approvals[id]?.note}
                      on:change={handleChangeEvent({ id, feedId }, 'note')}
                      hideLabel
                      rows={3}
                      labelText="Notes"
                      placeholder="Notes"
                    />
                  </div>
                </AccordionItem>
              {:else}
                <li class="li">
                  <div class="li-content">
                    <Tag type={typeToColor[type]}>{name}</Tag>
                    {#if difference}
                      <ChangedFeedDifference {difference} />
                    {:else}
                      <strong>{startDate} &mdash; {endDate}</strong>
                    {/if}
                  </div>
                </li>
              {/if}
            {/each}
          </ul>
        </Accordion>
      </div>
    {/if}
  {/each}
  {#if requireApproval.length}
    <div class="required">
      <Checkbox labelText="I confirm that I have the approvals required for this entitlement to proceed" bind:checked={understood} />
    </div>
  {/if}
</Modal>

<style>
  .save-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .change-type-wrapper:not(:first-child) {
    margin-top: 2rem;
  }

  .change-type-wrapper .li {
    margin-left: 1rem;
    min-height: 2rem;
    padding: 0.3125rem 0;
  }

  .change-type-wrapper :global(li li:last-child) {
    margin-bottom: 1rem;
  }

  .change-type-wrapper :global(.bx--accordion__item) {
    border-top: unset !important;
    border-bottom: unset !important;
  }

  .change-type-wrapper :global(.bx--accordion__content) {
    margin-left: 1.5rem;
  }

  .change-type-wrapper :global(textarea) {
    margin-top: 0.25rem;
  }

  .tag-align :global(.bx--tag span) {
    display: flex;
  }

  .tag-align :global(.bx--tag span svg) {
    margin-right: 0.15rem;
    width: 12px;
  }

  .required {
    position: fixed;
    bottom: 75px;
    background-color: var(--cds-ui-01);
  }
</style>
