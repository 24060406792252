<script>
  import { onMount } from 'svelte';

  import { InlineNotification, TabContent } from 'carbon-components-svelte';

  import DataFeedBasicInformation from './DataFeedBasicInformation.svelte';
  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';
  import TabsWithRouting from '../../../components/TabsWithRouting.svelte';
  import { cacheEntityName } from '../../../stores';
  import { getDataFeed, getDataFeedApprovalServices } from '../../../services';

  export let dataFeedId;

  let pageData = { loading: true };

  const loadDataFeedData = async () => {
    pageData = { ...pageData, loading: true };
    try {
      const [dataFeedDetails, approvalServiceIds] = await Promise.all([getDataFeed(dataFeedId), getDataFeedApprovalServices(dataFeedId)]);

      pageData = { loading: false, dataFeed: { ...dataFeedDetails, approvalServiceIds } };
      cacheEntityName(dataFeedId, dataFeedDetails.name);
    } catch (error) {
      console.error('[DataFeedDetails] Failed to retrieve data feed data!', error);
      pageData = { loading: false, error };
    }
  };

  onMount(loadDataFeedData);
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load data feed data! Please try again later."
  />
{/if}

{#if !pageData.loading || pageData.dataFeed}
  <TabsWithRouting
    tabsWrapperProps={{ 'aria-label': 'Data Feed details tabs' }}
    tabs={[{ label: 'Basic Information', hash: '#basic-information' }]}
  >
    <TabContent><DataFeedBasicInformation {dataFeedId} dataFeed={pageData.dataFeed} on:dataFeedUpdated={loadDataFeedData} /></TabContent>
  </TabsWithRouting>
{/if}
