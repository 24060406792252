<script>
  import { Button, Checkbox } from 'carbon-components-svelte';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import Save16 from 'carbon-icons-svelte/lib/Save16';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { toLocalHyphenatedDateString } from '../../../../../shared/convertDates';

  import StatusIcon from '../../../../components/StatusIcon.svelte';

  export let cell, feedInventory, feedToPath, row, rowValidationErrors, visibleFeeds;

  const handleDeleteFeed = () => {
    visibleFeeds = visibleFeeds.filter(({ id }) => id !== row.id);
  };

  const handleToggleFeed = () => {
    if (row.isEdited && Object.values(rowValidationErrors).some(Boolean)) {
      return;
    }
    saveFeed({ isEdited: !row.isEdited });
  };

  const handleChangeDate = ({ target: { value } }, key) => {
    saveFeed({ [key]: value });
  };

  const handleToggleIsOngoing = (event) => {
    event.preventDefault();
    if (row.expiresAt) {
      return;
    }
    saveFeed({ isOngoing: !row.isOngoing });
  };

  const handleToggleExpiresAt = (event) => {
    event.preventDefault();
    const saveObj = {};
    const { availabilityStartDate, availabilityStartMonth, availabilityEndMonth } = feedInventory.find(({ id }) => id === row.feedId);

    const today = toLocalHyphenatedDateString(new Date());

    if (row.expiresAt) {
      saveObj.expiresAt = null;
      saveObj.startDate = availabilityStartMonth ?? today.slice(0, 7);
      saveObj.endDate = availabilityEndMonth ?? today.slice(0, 7);
    } else {
      saveObj.expiresAt = today;
      saveObj.isOngoing = false;
      saveObj.startDate = availabilityStartDate ?? today;
      saveObj.endDate = availabilityStartDate ?? today;
    }
    saveFeed(saveObj);
  };

  const handleExpiresAtChange = ({ target: { value } }) => {
    saveFeed({ expiresAt: value });
  };

  const saveFeed = (obj) => {
    visibleFeeds = visibleFeeds.map((feed) => (feed.id !== row.id ? feed : { ...feed, ...obj }));
  };
</script>

{#if cell.key === 'actions'}
  <Button
    size="sm"
    kind="danger-tertiary"
    tooltipPosition="left"
    tooltipAlignment="end"
    iconDescription="Delete entitlement"
    icon={TrashCan16}
    on:click={handleDeleteFeed}
  />
  <Button
    size="sm"
    kind="tertiary"
    tooltipPosition="left"
    tooltipAlignment="end"
    iconDescription={row.isEdited ? 'Save feed' : 'Edit Feed'}
    icon={row.isEdited ? Save16 : Edit16}
    on:click={handleToggleFeed}
  />
{:else if cell.key === 'startDate' && feedToPath[row.name]}
  <div class="note">
    <em>No date range for custom paths</em>
  </div>
{:else if row.isEdited && cell.key === 'startDate'}
  <TextInput
    hideLabel
    name="startDate"
    labelText="Start Date"
    placeholder="Start date"
    required
    size="sm"
    invalid={rowValidationErrors[cell.key]}
    invalidText={rowValidationErrors[cell.key]}
    value={row[cell.key]}
    on:blur={(e) => handleChangeDate(e, cell.key)}
  />
{:else if cell.key === 'endDate' && feedToPath[row.name]}
  <div class="note">
    <em>No date range for custom paths</em>
  </div>
{:else if row.isEdited && cell.key === 'endDate'}
  <TextInput
    hideLabel
    name="endDate"
    labelText="End Date"
    placeholder="End date"
    required
    size="sm"
    invalid={rowValidationErrors[cell.key]}
    invalidText={rowValidationErrors[cell.key]}
    value={row[cell.key]}
    on:blur={(e) => handleChangeDate(e, cell.key)}
  />
{:else if row.isEdited && cell.key === 'isOngoing'}
  <Checkbox
    labelText="Ongoing"
    hideLabel
    checked={row.isOngoing}
    on:click={handleToggleIsOngoing}
    disabled={Boolean(row.expiresAt) || Boolean(feedToPath[row.name])}
  />
{:else if row.isEdited && cell.key === 'expiresAt'}
  <div class="feed-dates-wrapper demo-date-wrapper">
    <Checkbox labelText="Demo Access" hideLabel checked={Boolean(row.expiresAt)} on:click={handleToggleExpiresAt} />
    <TextInput
      inline
      hideLabel
      invalid={rowValidationErrors.expiresAt}
      invalidText={rowValidationErrors.expiresAt}
      labelText="Demo Expires At"
      placeholder={row.expiresAt && 'yyyy-mm-dd'}
      disabled={!row.expiresAt}
      size="sm"
      value={row.expiresAt}
      on:blur={handleExpiresAtChange}
    />
  </div>
{:else if !row.isEdited && cell.key === 'isOngoing'}
  <StatusIcon valid={Boolean(row.isOngoing)} />
{:else if cell.display}
  {cell.display(cell.value, row)}
{:else}
  {cell.value}
{/if}

<style>
  .demo-date-wrapper {
    display: inline-flex;
    justify-content: left;
    align-items: center;
  }

  .demo-date-wrapper :global(.bx--text-input-wrapper) {
    display: inline;
    margin-left: 0.5rem;
  }

  .note em {
    font-size: 12px;
    color: var(--cds-text-helper);
  }
</style>
