<script>
  import { Button, Checkbox, Link } from 'carbon-components-svelte';
  import TrashCan16 from 'carbon-icons-svelte/lib/TrashCan16';
  import Edit16 from 'carbon-icons-svelte/lib/Edit16';
  import Save16 from 'carbon-icons-svelte/lib/Save16';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { toLocalHyphenatedDateString } from '../../../../../shared/convertDates';

  import StatusIcon from '../../../../components/StatusIcon.svelte';

  export let cell,
    feedInventory,
    feedToPath,
    row,
    rowValidationErrors,
    visibleFeeds,
    approval,
    handleClickForModal,
    feedListRequiringApproval,
    serviceId,
    dbFeeds;

  const handleDeleteFeed = () => {
    visibleFeeds = visibleFeeds.filter(({ id }) => id !== row.id);
  };

  const handleToggleFeed = () => {
    if (row.isEdited && Object.values(rowValidationErrors).some(Boolean)) {
      return;
    }
    saveFeed({ isEdited: !row.isEdited });
  };

  const handleChangeDate = ({ target: { value } }, key) => {
    saveFeed({ [key]: value });
  };

  const handleToggleIsOngoing = (event) => {
    event.preventDefault();
    if (row.expiresAt) {
      return;
    }
    saveFeed({ isOngoing: !row.isOngoing });
  };

  const handleToggleExpiresAt = (event) => {
    event.preventDefault();
    const saveObj = {};
    const { availabilityStartDate, availabilityStartMonth, availabilityEndMonth } = feedInventory.find(({ id }) => id === row.feedId);

    const today = toLocalHyphenatedDateString(new Date());

    if (row.expiresAt) {
      saveObj.expiresAt = null;
      saveObj.startDate = availabilityStartMonth ?? today.slice(0, 7);
      saveObj.endDate = availabilityEndMonth ?? today.slice(0, 7);
    } else {
      saveObj.expiresAt = today;
      saveObj.isOngoing = false;
      saveObj.onGoingMaxDate = null;
      saveObj.startDate = availabilityStartDate ?? today;
      saveObj.endDate = availabilityStartDate ?? today;
    }
    saveFeed(saveObj);
  };

  const handleOnGoingMaxDateChange = ({ target: { value } }) => {
    saveFeed({ onGoingMaxDate: value });
  };

  const handleExpiresAtChange = ({ target: { value } }) => {
    saveFeed({ expiresAt: value });
  };

  const saveFeed = (obj) => {
    visibleFeeds = visibleFeeds.map((feed) => (feed.id !== row.id ? feed : { ...feed, ...obj }));
  };

  $: feedSetRequiringApproval = new Set(feedListRequiringApproval);
  $: dbEntitlementIdsSet = new Set(dbFeeds.map(({ id }) => id));
</script>

{#if cell.key === 'actions'}
  <Button
    size="sm"
    kind="danger-tertiary"
    tooltipPosition="left"
    tooltipAlignment="end"
    iconDescription="Delete entitlement"
    icon={TrashCan16}
    on:click={handleDeleteFeed}
  />
  <Button
    size="sm"
    kind="tertiary"
    tooltipPosition="right"
    tooltipAlignment="end"
    iconDescription={row.isEdited ? 'Save feed' : 'Edit Feed'}
    icon={row.isEdited ? Save16 : Edit16}
    on:click={handleToggleFeed}
  />
{:else if cell.key === 'startDate' && feedToPath[row.name]}
  <div class="note">
    <em>No date range for custom paths</em>
  </div>
{:else if row.isEdited && cell.key === 'startDate'}
  <TextInput
    hideLabel
    name="startDate"
    labelText="Start Date"
    placeholder="Start date"
    required
    size="sm"
    invalid={rowValidationErrors[cell.key]}
    invalidText={rowValidationErrors[cell.key]}
    value={row[cell.key]}
    on:blur={(e) => handleChangeDate(e, cell.key)}
  />
{:else if cell.key === 'endDate' && feedToPath[row.name]}
  <div class="note">
    <em>No date range for custom paths</em>
  </div>
{:else if row.isEdited && cell.key === 'endDate'}
  <TextInput
    hideLabel
    name="endDate"
    labelText="End Date"
    placeholder="End date"
    required
    size="sm"
    invalid={rowValidationErrors[cell.key]}
    invalidText={rowValidationErrors[cell.key]}
    value={row[cell.key]}
    on:blur={(e) => handleChangeDate(e, cell.key)}
  />
{:else if row.isEdited && cell.key === 'isOngoing'}
  <div class="date-wrapper">
    <Checkbox
      labelText="Ongoing"
      hideLabel
      checked={row.isOngoing}
      on:click={handleToggleIsOngoing}
      disabled={Boolean(row.expiresAt) || Boolean(feedToPath[row.name])}
    />
    <TextInput
      inline
      hideLabel
      invalid={rowValidationErrors.onGoingMaxDate}
      invalidText={rowValidationErrors.onGoingMaxDate}
      labelText="Max Date"
      placeholder={row.onGoingMaxDate ?? 'Max Date (YYYY-MM)'}
      disabled={!row.isOngoing}
      size="sm"
      value={row.onGoingMaxDate}
      on:blur={handleOnGoingMaxDateChange}
    />
  </div>
{:else if row.isEdited && cell.key === 'expiresAt'}
  <div class="date-wrapper">
    <Checkbox labelText="Demo Access" hideLabel checked={Boolean(row.expiresAt)} on:click={handleToggleExpiresAt} />
    <TextInput
      inline
      hideLabel
      invalid={rowValidationErrors.expiresAt}
      invalidText={rowValidationErrors.expiresAt}
      labelText="Demo Expires At"
      placeholder={row.expiresAt ?? 'Expiration Date'}
      disabled={!row.expiresAt}
      size="sm"
      value={row.expiresAt}
      on:blur={handleExpiresAtChange}
    />
  </div>
{:else if !row.isEdited && cell.key === 'isOngoing'}
  <div class="wrap">
    <StatusIcon valid={Boolean(row.isOngoing)} />
    {#if row.onGoingMaxDate}
      <div class="note">
        <em>until {row.onGoingMaxDate}</em>
      </div>
    {/if}
  </div>
{:else if (approval || (feedSetRequiringApproval.has(row.feedId) && dbEntitlementIdsSet.has(row.id))) && cell.key === 'name'}
  <div class="link">
    <Link
      on:click={handleClickForModal('editApproval', {
        approval,
        entitlement: {
          entitlementId: row.id,
          permissionId: row.permissionId,
          feedId: row.feedId,
          serviceId,
        },
      })}>{cell.value}</Link
    >
  </div>
{:else if cell.display}
  {cell.display(cell.value, row)}
{:else}
  {cell.value}
{/if}

<style>
  .date-wrapper {
    display: inline-flex;
    justify-content: left;
    align-items: center;
  }

  .date-wrapper :global(.bx--text-input-wrapper) {
    display: inline;
    margin-left: 0.5rem;
  }

  .wrap {
    display: flex;
  }

  .wrap .note {
    margin-left: 0.5rem;
  }

  .note em {
    font-size: 12px;
    color: var(--cds-text-helper);
  }

  .link :global(a) {
    cursor: pointer;
  }
</style>
